<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <!-- <div class="sidebar">
        <div
          v-for="(item, index) in sidebar"
          :key="index"
          :class="
            sidebarIndex == index ? 'sidebarActive sidebarItem' : 'sidebarItem'
          "
          @click="sidebarChange('#sidebar' + index, index)"
        >
          <div class="tip">{{ item.tip }}</div>
          <div class="title">{{ item.title }}</div>
        </div>
      </div> -->

      <div class="cont">
        <!-- <div class="title">{{ info.title }}</div> -->
        <!-- <div class="titleTwo" id="sidebar0" v-if="info.content != ''">
          <span class="dian"></span>
          <div>研发项目</div>
        </div> -->
        <div v-html="info.content" class="contCont"></div>
        <!-- <div class="titleTwo" id="sidebar1" v-if="info.content_yy != ''">
          <span class="dian"></span>
          <div>应用场景</div>
        </div> -->
        <div v-html="info.content_yy" class="contCont"></div>
        <!-- <div class="titleTwo" id="sidebar2" v-if="info.content_yy != ''">
          <span class="dian"></span>
          <div>工作范畴</div>
        </div> -->
        <div v-html="info.content_gz" class="contCont"></div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：技术研发-研发项目-项目详情
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-18 14:49
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "技术研发",
          title_en: "Technology R & D",
          path: "TechnologyRD",
        },
        {
          title_zh: "研发项目",
          title_en: "R & D project",
          path: "researchDirection",
        },
        // {
        //   title_zh: "",
        //   title_en: "",
        //   path: "",
        // },
      ],
      sidebar: [
        // {
        //   tip: "壹",
        //   title: "研发项目",
        // },
        // {
        //   tip: "贰",
        //   title: "应用场景",
        // },
        // {
        //   tip: "叁",
        //   title: "工作范畴",
        // },
      ],
      sidebarIndex: 0,
      info: "",
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getProjectDetail();
  },
  methods: {
    /**
     * 侧边栏切换
     * 刘嘉鑫
     * 2022-8-18
     */
    sidebarChange(selector, index) {
      // this.$el.querySelector(selector).scrollIntoView();
      this.sidebarIndex = index;
      this.$nextTick(() => {
        this.$el.querySelector(selector).scrollIntoView({
          behavior: "smooth", //定义动画过渡效果"auto"或 "smooth" 之一。默认为 "auto"。
          block: "center", //定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"。
          inline: "nearest", //"start", "center", "end", 或 "nearest"之一。默认为 "nearest"。
        });
      });
    },
    /**
     * 研发项目
     * 刘嘉鑫
     * 2022-8-19
     */
    getProjectDetail() {
      this.$request({
        url: "/Index/projectInfo",
        data: {
          id: this.id,
        },
      }).then((res) => {
        console.log("研发详情", res);
        res.project_info.content = res.project_info.content.replace(
          new RegExp("<img", "g"),
           "<img style='max-width:100%;height:auto;margin:0 auto;'"
        );
        res.project_info.content_yy = res.project_info.content_yy.replace(
          new RegExp("<img", "g"),
           "<img style='max-width:100%;height:auto;margin:0 auto;'"
        );
        res.project_info.content_gz = res.project_info.content_gz.replace(
          new RegExp("<img", "g"),
           "<img style='max-width:100%;height:auto;margin:0 auto;'"
        );
        this.info = res.project_info;
        if (res.project_info.content != "") {
          this.sidebar.push({
            tip: "壹",
            title: "研发项目",
          });
        }
        if (res.project_info.content_yy != "") {
          this.sidebar.push({
            tip: "贰",
            title: "应用场景",
          });
        }
        if (res.project_info.content_gz != "") {
          this.sidebar.push({
            tip: "叁",
            title: "工作范畴",
          });
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  background: #f6f6f6;
  padding: 78px 310px 120px;
  display: flex;
  align-items: flex-start;
}

.sidebar {
  width: 14%;
  background: #ffffff;
  padding: 30px 20px 0;
  margin-right: 41px;

  .sidebarItem {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 2px solid #eeeeee;
    margin-bottom: 30px;
    cursor: pointer;
    > .tip {
      padding: 5px;
      background: #e2e2e2;
      font-size: 16px;
      font-family: FZCuHeiSongS-B-GB;
      font-weight: 400;
      color: #999999;
      margin-right: 15px;
    }

    .title {
      font-size: 18px;
      font-family: FZFengYaSongS-GB;
      font-weight: 400;
      color: #666666;
    }
  }

  .sidebarActive {
    .tip {
      background: #ba0003 !important;
      color: #ffffff !important;
    }
    .title {
      color: #1a2a60 !important;
    }
  }

  .sidebarItem:last-child {
    border: none !important;
    margin: 0 0 10px 0 !important;
  }
}

.cont {
  background: #ffffff;
  padding: 58px;
  // width: 71%;

  .title {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #07257d;
    margin-bottom: 77px;
  }

  .titleTwo {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #1a2a60;
      border-radius: 50%;
      margin-right: 20px;
    }

    div {
      font-size: 26px;
      font-family: FZFengYaSongS-GB;
      font-weight: bold;
      color: #222222;
    }
  }

  .contCont {
    margin-bottom: 80px;
  }
}
</style>